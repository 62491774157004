.header {
    position: relative;
    display: flex;
    margin: 0;
    width: 100%;
    height: 45rem;
    background-image: url(../NRF25/new-york.png);
    background-size: cover;
    flex-direction: column;
    background-repeat: no-repeat;
    image-rendering: optimizeSpeed;
    background-blend-mode: lighten;
    z-index: 0;
    /* Adicione isso para garantir que a header esteja abaixo do vídeo */
}

a{
    text-decoration: none;
}


.header-title-box {
    margin-top: 23.5rem;
    /*border: 1px solid white;*/
    width: 90%;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.sub-title-1 {
    width: 20rem;
    height: 2rem;
    margin-top: 1rem;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.sub-title-1 strong {
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    font-size: 10pt;
    letter-spacing: 1px;
}

.title-box {
    height: 9.5rem;
    border-bottom: 1px rgba(255, 255, 255, 0.368) solid;
    border-top: 1px rgba(255, 255, 255, 0.368) solid;
    width: 100%;
}

.title-box h1 {
    font-size: 35pt;
    color: white;
    width: 75%;
    font-weight: 500;
    letter-spacing: -0.2px;
    margin-top: 1rem
}

h1 strong {
    color: #E4B575;
    font-weight: 500;
    font-size: 35pt;
    letter-spacing: -0.2px;
}

.getabout {
    display: flex;
    width: 100%;
    height: 35rem;
    margin: auto;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
}

.line-header {
    width: 100%;
    height: 4rem;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.line-header img {
    margin-top: 0.2rem;
    width: 7rem;
    height: 2.3rem;
}

.line-bottom {
    margin-top: 1.5rem;
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sub-title-2 {
    width: 35rem;
    height: 100%;
}

.sub-title-2 p {
    font-weight: 400;
    text-align: left;
    color: #B2B2B2;
    font-size: 12pt;
    margin: 0;
}


.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    z-index: 1;
    /* Adicione isso para garantir que o vídeo esteja no fundo */
    overflow: clip;
    mix-blend-mode: screen;
}

.cta {
    height: 2.75rem;
    width: 15rem;
    background: linear-gradient(#E4B575, #b9935d);
    border: 1.75px solid #ffe9caca;
    border-radius: 50px;
    align-items: center;
    display: flex;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0px 20px 7px #e4b67544;
}

.plus {
    width: 20rem;
}

.center {
    margin: auto;
}

.top-off {
    margin-top: 0;
}

.top-on {
    margin-top: 2rem;
}

.cta p {
    color: #181818;
    font-size: 12pt;
    margin: auto;
    font-weight: 600;
}

.title-about {
    margin: 0.5rem auto 0 auto;

}

.title-about h2 {
    color: white;
    font-family: Inter;
    font-weight: 300;
    font-size: 21pt;
}

.title-about strong {
    font-weight: 600;
}

.box-about {
    margin: 1rem auto auto auto;
    width: 55rem;
    height: 18rem;
    display: flex;
    flex-direction: row;
}

.content-item {
    width: 21rem;
    height: 80%;
    margin: auto;
    border-radius: 10px;
    background: #181818;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
}

.content-item svg {
    height: 2.75rem;
}

.text {
    font-size: 11pt;
    padding-top: 1.5rem;
    color: #B2B2B2;
    font-weight: 400;
    font-family: Inter;
}

.text-2 {
    font-size: 10pt;
    color: #B2B2B2;
    font-weight: 400;
    font-family: Inter;
}


.nrfabout {
    /*border: 1px red solid;*/
    height: 40rem;
    width: 100%;
}

.box-nrf {
    height: 25rem;
    width: 60rem;
    background: #181818;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.img-nrf {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;
    width: 18rem;
    background-image: url(../NRF25/Rectangle\ 8.png);
    opacity: 100%;
    background-size: cover;
}

.text-nrf {
    height: 80%;
    width: 60%;
    display: flex;
    flex-direction: column;
}

.text-nrf svg {
    margin-left: -10px;
    height: 2.5rem;
}

.text-nrf h2 {
    margin-top: 10px;
    color: white;
    font-family: Inter;
    font-weight: 600;
}

.text-nrf p {
    padding-top: 0;
}

.line-itens {
    margin-top: 1rem;
    width: 60rem;
    height: 13.2rem;

    display: flex;
    flex-direction: row;
}

.item {
    margin: auto;
    height: 86%;
    padding: 20px;
    width: 11.5rem;
    background: #181818;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.first-margin {
    margin-left: 0;
}

.last-margin {
    margin-right: 0;
}

.item svg {
    height: 2rem;
    width: 2rem;
}

.item h4 {
    margin: 0;
    padding-top: 7px;
    color: white;
    font-size: 14pt;
    font-weight: 600;
}

.renzo{
    margin: 2rem auto auto auto;
    width: 35rem;
    height: 12rem;
    display: flex;
    flex-direction: row;
}

.event {
    height: 30rem;
    width: 100%;
    margin-top: 5rem;
    background-color: #FFFFFF;
}

.marker {
    border-top: 1px red solid;
    border-bottom: 1px red solid;
}

.event-title {
    width: 30rem;
    margin: auto;
    text-align: center;
    margin-top: 2rem
}

.event-title h3 {
    font-weight: 600;
    font-size: 23pt;
}

.event-title strong {
    color: #E4B575;
    text-shadow: #e4b675cf 0px 0px 35px;
}

.event-img {
    height: 20rem;
    width: 35rem;
    border-radius: 10px;
    margin: auto;
    background-image: url(../NRF25/alfredo.png);
    background-size: cover;
    display: flex;
    align-items: end;
    box-shadow: 0px 0px 35px 8px #1818187b;
}

.name-tag {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    height: 2rem;
    width: 100%;
    background: #18181894;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px
}

.name-tag h2 {
    color: white;
    font-family: Inter;
    font-weight: 500;
    font-size: 12pt;
    margin-top: 0.5rem;
    padding: 0;

}


.event-text {
    width: 35rem;
    margin: auto;
    text-align: center;
}


.event-text p {
    font-size: 11pt;
    padding-top: 0.5rem;
    color: #3B3B3B;
    font-weight: 400;
    font-family: Inter;
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    background: #181818;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    align-items: end;
}

.overlay-content button {
    border: none;
    width: 2.2rem;
    height: 2.2rem;
    background: none;
    margin-top: 10px;
    cursor: pointer;
    margin-left: 90%;

}

.agend {
    height: 65rem;
    width: 100%;
}

.agend-title {
    height: 5rem;
    width: 20rem;
    text-align: center;
}

.agend-title h3 {
    color: #B0B0B0;
    font-family: Inter;
    font-weight: 300;
    font-size: 22pt;
    padding: 0;
    margin: 0;
}

.agend-title strong {
    color: white;
    font-weight: 600;
}

.box-agend-center {
    height: 39rem;
    width: 40.5rem;
    margin: 50px auto;
    align-items: center;
    position: relative;
}

.renzo p{
    font-size: 11pt;
    color: #3B3B3B;
    font-weight: 400;
    font-family: Inter;
    padding-left: 20px;
    padding-top: 0;
    margin: 0;
}

.renzo p strong{
    font-size: 16pt;
    color: #000000;
    font-weight: 600;
    font-family: Inter;
    padding-top: 0;
    margin: 0;
}

.renzo img{
    height: 12rem;
}

.block {
    color: white;
    padding: 10px;
    width: 17.7rem;
    height: 2.5rem;
    position: relative;
    bottom: 1rem;
}

.block h4 {
    padding: 0;
    margin: 0;
    font-family: Inter;
    font-weight: 600;
}

.block small {
    color: #B0B0B0;
}

.block strong {
    color: #E4B575;
    text-shadow: 1px 1px 25px #e4b6759e;
}

.right-block {
    left: 53%;
    text-align: left;
    padding-right: 10px;
}

.left-block {
    left: 0%;
    text-align: right;
    padding-left: 10px;
}

.left-block .point {
    height: 2rem;
    width: 2rem;
    border-radius: 100px;
    background: #E4B575;
    position: absolute;
    right: -36px;
    z-index: 10;
    top: 10px;

}

.right-block .point {
    height: 2rem;
    width: 2rem;
    border-radius: 100px;
    background: #E4B575;
    position: absolute;
    left: -36px;
    z-index: 10;
    top: 10px;
    box-shadow: 1px 1px 40px #e4b67572;
}

.box-agend-center::after {
    content: '';
    position: absolute;
    width: 5px;
    background: #E4B575;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: -1;
    margin-left: -3px;
}

.attention {
    margin: auto;
    display: flex;
    width: 30rem;
    display: flex;
    text-align: center;
    align-items: center;
}

.attention h5 {
    color: #B2B2B2;
    text-align: center;
    margin: 0 auto;
    font-weight: 500;
}

.box-tags {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 35rem;
    height: 8rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.tag {
    width: 9rem;
    height: 2rem;
    border-radius: 50px;
    border: #252525 1px solid;
    margin: auto;
    text-align: center;
    align-items: center;
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
}

.tag p {
    color: #B0B0B0;
    font-weight: 500;
    font-size: 8.2pt;
    margin: auto;

}

.line-tag {

    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.pad {
    padding-left: 40px;
    padding-right: 40px;
    width: 12rem;
}

.pad-2 {
    width: 11rem;
}

.pricing {
    width: 100%;
    height: 38rem;
}

.box-pricing {
    margin: auto;
    width: 50rem;
    height: 36rem;
    display: flex;
    flex-direction: column;
}

.title-pricing {
    height: 6rem;
    width: 30rem;
    margin: auto;
    text-align: center;
    display: flex;
}

.title-pricing h2 {
    color: #B0B0B0;
    font-family: Inter;
    font-weight: 500;
}

.title-pricing strong {
    color: white;
    font-weight: 600;
}

.card-pricing {
    height: 29rem;
    width: 48rem;
    background: #181818;
    margin: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 60px 7px #63636341;
    border: 0.5px solid #b2b2b227;
}

.card-pricing img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #B0B0B0;
    width: 17rem;
}

.princing-box-text {
    height: 27rem;
    width: 32rem;
    padding: 0px 20px 0px 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
}


.title-card-princing {
    margin: 10px auto 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 4rem;
}

.title-card-princing p {
    margin: 0;
    padding: 0;
    color: #B2B2B2;
    font-size: 11pt;
}

.title-card-princing h4 {
    color: white;
    font-size: 24pt;
    padding: 0;
    margin: 0;
}

.title-card-princing strong {
    color: #B2B2B2;
    font-size: 11pt;
    font-weight: 400;
    padding-left: 5px;
}

.box-itens {
    height: 17.5rem;
    display: flex;
    flex-direction: column;
}

.line-check {
    width: 98%;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: 3px;
    margin-top: 10px;
}

.line-check p {
    margin: 0;
    padding: 0 5px;
    font-weight: 400;
    font-size: 10pt;
    color: #B2B2B2;
}


.sugests {
    width: 100%;
    height: 85rem;
    display: flex;
    flex-direction: column;
}

.ticket {
    margin: 1rem auto 0 auto;
    height: 15rem;
    width: auto;
}

.title-airTicket {
    height: 6rem;
    width: 20rem;
    margin: 2rem auto 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
}


.title-airTicket h2 {
    padding-top: 5px;
    color: white;
    margin: 0;
    font-weight: 600;
}

.title-airTicket svg {
    margin: 0 auto 0 auto;
    height: 3rem;
}

.hosped {
    height: 24rem;
    width: 45rem;
    background: #181818;
    margin: auto;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    flex-direction: row;

}

.hosped img {
    height: 24rem;
    margin-left: -5rem;
}

.more {
    width: 26rem;
}

.hosped-content {
    padding: 10px 15px 20px 10px;
    margin: auto;
    height: 80%;
    width: 27rem;
    display: flex;
    flex-direction: column;
}

.hosped-content h3 {
    color: white;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.hosped-content p {
    color: #B0B0B0;
    padding: 0;
    margin: 0;
    font-size: 10pt;
}

.line-svg svg {
    height: 1.7rem;
    width: 1.7rem;
}

.line-svg {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
    margin-top: 2rem;
}

.line-svg p {
    margin: auto 0 auto 0;
    padding-left: 5px;
    font-weight: 500;
}


.room-colum {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    height: 11rem;
}

.room {
    height: 100%;
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: start;
}

.room svg {
    height: 2rem;
    width: 2rem;
    padding: 0;
}

.room h4 {
    color: rgba(255, 255, 255, 0.673);
    font-weight: 600;
    font-size: 10pt;
    margin-top: 0;
}

.room h3 {
    font-size: 12pt;
}

.feedback {
    height: 25rem;
    width: 100%;

}

.box-feed {
    width: 45rem;
    height: 65%;
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-top: 1rem;
}

.feed {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


.feed img {
    height: 7.5rem;
    width: 7.5rem;
    margin: 1rem auto 0 auto;
}

.feed h4 {
    height: 1.5rem;
    width: 15.5rem;
    margin: 0rem auto 0 auto;
    color: white;
    font-weight: 600;
    padding: 0;
    font-size: 13pt;
    text-align: center;
}

.feed p {
    color: #B0B0B0;
    font-weight: 400;
    font-size: 11pt;
    text-align: center;
    margin: 1rem auto auto auto;
}

.feed-title h4 {
    color: white;
    font-weight: 600;
    font-size: 16pt;
}

.feed-title {
    text-align: center;
    margin: auto;
    width: 20rem;
}

.publi {
    width: 100%;
    background-color: black;
    height: 5rem;
    display: flex;
    flex-direction: column;
}

.line-publi {
    height: 4rem;
    width: 35%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.line-publi img {
    height: 2.2rem;
    margin: auto;
}

.line-publi .sup {
    height: 3.5rem;
    margin: auto;
}

.sup {
    height: 3.5rem;
}


.esp {
    height: 35rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-esp {
    margin: auto;
    height: 2rem;
}

.title-esp h3 {
    color: white;
    margin: auto;
    font-weight: 600;
    font-size: 17pt;
}

.line-esp img {
    height: 20rem;
    margin: 0rem auto;
}

.line-esp {
    display: flex;
    margin: 0rem auto auto auto;
    width: 37%;
}



@media only screen and (max-width: 600px) {
    .header {
        position: relative;
        display: flex;
        margin: 0;
        width: 100%;
        height: 35rem;
        background-image: url(../NRF25/img-header-phone.png);
        background-size: cover;
        flex-direction: column;
        background-repeat: no-repeat;
        image-rendering: optimizeSpeed;
        background-blend-mode: lighten;
        z-index: 0;
        /* Adicione isso para garantir que a header esteja abaixo do vídeo */
    }

    .header-title-box {
        margin-top: 10.5rem;

        width: 90%;
        height: 20rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;

    }

    .sub-title-1 {
        width: 20rem;
        height: 2rem;
        margin-top: 0.5rem;
        justify-content: space-between;
        display: flex;
        flex-direction: row;

    }

    .sub-title-1 strong {
        font-weight: 400;
        text-align: left;
        color: #FFFFFF;
        font-size: 9pt;
        letter-spacing: 1px;
    }

    .title-box {
        height: 8.5rem;
        border-bottom: 1px rgba(255, 255, 255, 0.368) solid;
        border-top: 1px rgba(255, 255, 255, 0.368) solid;
        width: 100%;
    }

    .title-box h1 {
        font-size: 22pt;
        color: white;
        width: 90%;
        font-weight: 500;
        letter-spacing: -0.2px;
        margin-top: 1rem
    }

    h1 strong {
        color: #E4B575;
        font-weight: 500;
        font-size: 22pt;
        letter-spacing: -0.2px;
    }

    .line-header {
        width: 100%;
        height: 5rem;
        justify-content: space-between;
        display: flex;
        flex-direction: column-reverse;

    }

    .line-header img {
        margin-top: 0.2rem;
        width: 6.5rem;
        height: 2.3rem;
        margin-left: -5px;
    }

    .line-bottom {
        margin-top: 1.5rem;
        height: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .sub-title-2 {
        width: 20rem;
        height: 3.5rem;

    }

    .sub-title-2 p {
        font-weight: 400;
        text-align: left;
        color: #B2B2B2;
        font-size: 10pt;
        margin: 0;
    }


    .title-about {
        margin: 0.5rem auto 0 auto;

    }

    .title-about h2 {
        color: white;
        font-family: Inter;
        font-weight: 300;
        font-size: 21pt;
    }

    .title-about strong {
        font-weight: 600;
    }

    .box-about {
        margin: 1rem auto auto auto;
        width: 100%;
        height: 39rem;
        display: flex;
        flex-direction: column;
    }

    .content-item {
        width: 80%;
        height: 15rem;
        margin: auto;
        border-radius: 10px;
        background: #181818;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
    }

    .content-item svg {
        height: 2.75rem;
    }

    .text {
        font-size: 11pt;
        padding-top: 1.5rem;
        color: #B2B2B2;
        font-weight: 400;
        font-family: Inter;
    }

    .text-2 {
        font-size: 10pt;
        color: #B2B2B2;
        font-weight: 400;
        font-family: Inter;
    }

    .getabout {
        display: flex;
        width: 100%;
        height: 52rem;
        margin: auto;
        flex-direction: column;
    }


    .nrfabout {

        height: 103rem;
        width: 100%;
    }

    .box-nrf {
        height: 40rem;
        width: 90%;
        background: #181818;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        overflow: hidden;
    }

    .img-nrf {
        border-radius: none;
        border-bottom-left-radius: 0;
        height: 20rem;
        width: 100%;
        background-image: url(../NRF25/Rectangle\ 8.png);
        opacity: 100%;
        background-size: cover;
        margin-top: -10rem;
    }

    .text-nrf {
        margin-top: 2rem;
        height: 74%;
        width: 88%;
        display: flex;
        flex-direction: column;
    }

    .text-nrf svg {
        margin-left: -10px;
        height: 2.5rem;
    }

    .text-nrf h2 {
        margin-top: 10px;
        color: white;
        font-family: Inter;
        font-weight: 600;
    }

    .text-nrf p {
        padding-top: 0;
    }

    .line-itens {
        width: 100%;
        height: 60rem;
        display: flex;
        flex-direction: column;
        margin: 1rem auto auto auto;
    }

    .item {
        margin: auto;
        height: 10.5rem;
        padding: 20px;
        width: 18.5rem;
        background: #181818;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }

    .first-margin {
        margin: auto;
    }

    .last-margin {
        margin: auto;
    }

    .item svg {
        height: 2rem;
        width: 2rem;
    }

    .item h4 {
        margin: 0;
        padding-top: 7px;
        color: white;
        font-size: 14pt;
        font-weight: 600;
    }


    .event {
        height: 42rem;
        width: 100%;
        margin-top: 5rem;
        background-color: #FFFFFF;
    }

    .marker {
        border-top: 1px red solid;
        border-bottom: 1px red solid;
    }

    .event-title {
        width: 90%;
        margin: auto;
        text-align: center;
        margin-top: 2rem
    }

    .event-title h3 {
        font-weight: 600;
        font-size: 21pt;
    }

    .event-title strong {
        color: #E4B575;
        text-shadow: #e4b675cf 0px 0px 35px;
    }

    .event-img {
        height: 12rem;
        width: 20rem;
        border-radius: 10px;
        margin: auto;
        background-image: url(../NRF25/alfredo.png);
        background-size: cover;
        display: flex;
        align-items: end;
        box-shadow: 0px 0px 35px 8px #1818187b;
    }

    .name-tag {
        margin-left: auto;
        margin-right: auto;
        margin-top: 3rem;
        height: 1.5rem;
        width: 100%;
        background: #18181894;
        text-align: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px
    }

    .name-tag h2 {
        color: white;
        font-family: Inter;
        font-weight: 500;
        font-size: 10pt;
        margin-top: 0.2rem;
        padding: 0;

    }


    .event-text {
        width: 85%;
        margin: auto;
        text-align: center;
    }


    .event-text p {
        font-size: 11pt;
        padding-top: 0.5rem;
        color: #3B3B3B;
        font-weight: 400;
        font-family: Inter;
    }


    .agend {
        height: 75rem;
        width: 100%;
    }

    .agend-title {
        height: 5rem;
        width: 20rem;
        text-align: center;
    }

    .agend-title h3 {
        color: #B0B0B0;
        font-family: Inter;
        font-weight: 300;
        font-size: 22pt;
        padding: 0;
        margin: 0;
    }

    .agend-title strong {
        color: white;
        font-weight: 600;
    }

    .box-agend-center {
        height: 40rem;
        width: 98%;

        margin: 50px auto auto auto;
        align-items: center;
        position: relative;
    }



    .block {
        color: white;
        padding: 10px;
        width: 9rem;
        height: 2.5rem;
        position: relative;
        bottom: 1rem;
    }

    .block h4 {
        padding: 0;
        margin: 0;
        font-family: Inter;
        font-weight: 600;
    }

    .block small {
        color: #B0B0B0;
    }

    .block strong {
        color: #E4B575;
        text-shadow: 1px 1px 25px #e4b6759e;
    }

    .right-block {
        left: 200px;
        text-align: left;
        padding-right: 10px;
    }

    .left-block {
        left: -10px;
        text-align: right;
        padding-left: 10px;
    }

    .left-block .point {
        height: 2rem;
        width: 2rem;
        border-radius: 100px;
        background: #E4B575;
        position: absolute;
        right: -36px;
        z-index: 10;
        top: 10px;
        display: none;

    }

    .right-block .point {
        height: 2rem;
        width: 2rem;
        border-radius: 100px;
        background: #E4B575;
        position: absolute;
        left: -36px;
        z-index: 10;
        top: 10px;
        box-shadow: 1px 1px 40px #e4b67572;
        display: none;
    }

    .box-agend-center::after {
        content: '';
        position: absolute;
        width: 5px;
        background: #E4B575;
        height: 100%;
        top: 0;
        left: 50%;
        z-index: -1;
        margin-left: -3px;
    }

    .attention {
        margin: 1.5rem auto auto auto;
        display: flex;
        width: 90%;
        display: flex;
        text-align: center;
        align-items: center;
    }

    .attention h5 {
        color: #B2B2B2;
        text-align: center;
        margin: 0 auto;
        font-weight: 500;
    }


    .box-tags {
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        height: 16rem;
        padding: 10px;
        display: flex;
        flex-direction: column;

    }

    .tag {
        width: 9rem;
        height: 2rem;
        border-radius: 50px;
        border: #252525 1px solid;
        margin: auto;
        text-align: center;
        align-items: center;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }

    .tag p {
        color: #B0B0B0;
        font-weight: 500;
        font-size: 8.2pt;
        margin: auto;

    }

    .line-tag {

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .pad {
        padding-left: 40px;
        padding-right: 40px;
        width: 12rem;
    }

    .pad-2 {
        width: 11rem;
    }

    .pricing {
        width: 100%;
        height: 66rem;

    }


    .box-pricing {
        margin: auto;
        width: 90%;
        height: 65rem;
        display: flex;
        flex-direction: column;

    }

    .title-pricing {
        height: 8rem;
width: 90%;
        margin: 1rem auto auto auto;
        text-align: center;
        display: flex;
    }

    .title-pricing h2 {
        color: #B0B0B0;
        font-family: Inter;
        font-weight: 500;
    }

    .title-pricing strong {
        color: white;
        font-weight: 600;
    }

    .card-pricing {
        height: 50rem;
        width: 100%;
        background: #181818;
        margin: auto;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 60px 7px #63636341;
        border: 0.5px solid #b2b2b227;
        overflow: hidden;
    }

    .card-pricing img {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: #B0B0B0;
        width: 100%;
        height: 15rem;
        margin-top: -3rem;
    }

    .princing-box-text {
        height: 35rem;
        width: 85%;
        padding: 0px 20px 0px 20px;
        margin: auto;
        display: flex;
        flex-direction: column;

    }


    .title-card-princing {
        margin: 10px auto 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 4rem;
    }

    .title-card-princing p {
        margin: 0;
        padding: 0;
        color: #B2B2B2;
        font-size: 11pt;
    }

    .title-card-princing h4 {
        color: white;
        font-size: 24pt;
        padding: 0;
        margin: 0;
    }

    .title-card-princing strong {
        color: #B2B2B2;
        font-size: 11pt;
        font-weight: 400;
        padding-left: 5px;
    }

    .box-itens {
        height: 22.5rem;
        display: flex;
        width: 100%;
        flex-direction: column;

    }

    .line-check {
        width: 95%;
        height: 3rem;
        display: flex;
        flex-direction: row;
        text-align: left;
        padding: 3px;
        margin-top: 10px;
    }

    .line-check p {
        margin: 0;
        padding: 0 5px;
        font-weight: 400;
        font-size: 10pt;
        color: #B2B2B2;
    }


    .sugests {
        width: 100%;
        height: 100rem;
        display: flex;
        flex-direction: column;

    }

    .ticket {
        margin: 1rem auto 0 auto;
        width: 90%;
        height: 10rem;
    }

    .title-airTicket {
        height: 6rem;
        width: 20rem;
        margin: 2rem auto 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
    }


    .title-airTicket h2 {
        padding-top: 5px;
        color: white;
        margin: 0;
        font-weight: 600;
    }

    .title-airTicket svg {
        margin: 0 auto 0 auto;
        height: 3rem;
    }

    .hosped {
        height: 44rem;
        width: 90%;
        background: #181818;
        margin: auto;
        border-radius: 10px;
        display: flex;
        overflow: hidden;
        flex-direction: column;

    }

    .hosped img {
        height: 25rem;
        margin-top: -5rem;
        width: 130%;
    }

    .more {
        width: 21rem;
    }



    .hosped-content {
        padding: 30px 15px 20px 10px;
        margin: auto;
        height: 80%;
        width: 88%;
        display: flex;
        flex-direction: column;

    }

    .hosped-content h3 {
        color: white;
        margin: 0;
        padding: 0;
        font-weight: 600;
    }

    .hosped-content p {
        color: #B0B0B0;
        padding: 0;
        margin: 0;
        font-size: 10pt;
    }

    .line-svg svg {
        height: 1.7rem;
        width: 1.7rem;
    }

    .line-svg {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 2rem;
        margin-top: 2rem;
    }

    .line-svg p {
        margin: auto 0 auto 0;
        padding-left: 5px;
        font-weight: 500;
    }


    .room-colum {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 3rem;
        height: 11rem;
        padding-left: 5px;
    }

    .room {
        height: 100%;
        width: 50%;

        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .room svg {
        height: 2rem;
        width: 2rem;
        padding: 0;
    }

    .room h4 {
        color: rgba(255, 255, 255, 0.673);
        font-weight: 600;
        font-size: 10pt;
        margin-top: 0;
    }

    .room h3 {
        font-size: 12pt;
    }


    .feedback {
        height: 44rem;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .box-feed {
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        margin: 0rem auto auto auto;
        padding-top: 1rem;

    }

    .feed {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }


    .feed img {
        height: 7.5rem;
        width: 7.5rem;
        margin: 1rem auto 0 auto;
    }

    .feed h4 {
        height: 1.5rem;
        width: 15.5rem;
        margin: 0rem auto 0 auto;
        color: white;
        font-weight: 600;
        padding: 0;
        font-size: 13pt;
        text-align: center;
    }

    .feed p {
        color: #B0B0B0;
        font-weight: 400;
        font-size: 11pt;
        text-align: center;
        margin: 1rem auto auto auto;
    }

    .feed-title h4 {
        color: white;
        font-weight: 600;
        font-size: 16pt;
    }

    .feed-title {
        text-align: center;
        margin: 1rem auto 0 auto ;
        width: 20rem;
    }

    .esp {
        height: 50rem;
        width: 100%;
        display: flex;
        flex-direction: column;

    }
    
    .title-esp {
        margin: auto;
        height: 2rem;
    }
    
    .title-esp h3 {
        color: white;
        margin: auto;
        font-weight: 600;
        font-size: 17pt;
    }
    
    .line-esp img {
        height: 20rem;
        margin: 0rem auto auto auto;
    }
    
    .line-esp {
        display: flex;
        margin: 0rem auto auto auto;
        width: 90%;
        height: 40rem;
 
        flex-direction: column;
    }

    .publi {
        width: 100%;
        background-color: black;
        height: 17rem;
        display: flex;
        flex-direction: column;
    }
    
    .line-publi {
        height: 90%;
        width: 35%;
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .line-publi img {
        height: 2.2rem;
        margin: auto;
    }
    
    .line-publi .sup {
        height: 3.5rem;
        margin: auto;
    }
    
    .sup {
        height: 3.5rem;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .overlay-content {
        background: #181818;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        align-items: end;
        height: 100%;
        width: 100%;
        margin: auto;
    }
    
    .overlay-content a {
        border: none;
        width: 4.2rem;
        height: 4.2rem;
        background: none;
        margin-top: 10px;
        cursor: pointer;
        margin-left: 80%;
    display: flex;
    }

    .overlay-content a svg{
        margin: auto;
        
    }

    .renzo{
        margin: 2rem auto auto auto;
        width: 90%;
        height: 25rem;
        display: flex;
        flex-direction: row;
    }

}