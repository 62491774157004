.overlayBox{
    height: 40rem;
    width: 35rem;
    background-color: #181818;
    margin: auto;
    border-radius: 10px;
}

.overlayBox h2 {
    padding-top: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
}

.overlayBox2{
    height: 20rem;
    width: 45rem;
    background-color: #181818;
    margin: auto;
    border-radius: 10px;
}

.overlayBox2 h2 {
    padding-top: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
}


.overlayBox3{
    height: 12rem;
    width: 25rem;
    background-color: #181818;
    margin: auto;
    border-radius: 10px;
}

.overlayBox3 h2 {
    padding-top: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
}


form{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-size: 11pt;
    font-weight: normal;
    margin-bottom: 5px;
    color: #dcdcdc;
}

.form-group input {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    border: 1px solid #4B4B4B;
    border-radius: 100px;
    font-size: 16px;
    box-sizing: border-box;
    background: #2D2D2D;
    height: 2.75rem;
    color: #dcdcdc;
    margin: auto;
}

.form-group input::placeholder{
font-size: 11pt;
}

.form-group input:hover{
    transition: border-color 500ms;
    border-color: #ffe9caca;
    }

.form-group input:focus {
    border-color: #ffe9caca;
    outline: none;
}

button[type="submit"] {
    margin-top: 3rem;
    width: 100%;
    padding: 12px;
    height: 3rem;
    background: linear-gradient(#E4B575, #b9935d);
    color: rgb(5, 5, 5);
    border: 1.75px solid #ffe9caca;
    border-radius: 100px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 0px 20px 7px #e4b67544;
}

button[type="submit"]:hover {
    background: linear-gradient(#E4B575, #b9935d);
}

.line{
    width: 90%;
    height: 14rem;
    margin: auto;
    display: flex;
    flex-direction: row;
}

.card{
    height: 10rem;
    width: 10rem;
    background: #2D2D2D;
    margin: auto;
    border-radius: 5px;
    border: 1px solid #4B4B4B;
    transition: background 0.5s, border-color 0.5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.card:hover{
    height: 10rem;
    width: 10rem;
    background: #E4B575;
    margin: auto;
    border-radius: 5px;
    border: 1px solid #F6CD95;

}

.card svg{
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
}

.card p{
    margin-top:0.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    color: white;
    font-size: 11.5pt;
}

.cupom input{
    border-radius: 5px;
    width: 15rem;
    margin: auto;
    overflow: hidden;
}

.cupom{
    margin-top: 1.2rem;
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
}

.iconSVG{
    width: 4rem;
margin-top: 1px;
    height: 2.60rem;
    background: #2D2D2D;
    position: absolute;
    margin-left: 13.44rem;
    border-radius: 5px;
    border-right: 1px solid #4B4B4B;
    display: flex;
}

.iconSVG svg{
    margin: auto;
}

.loading-circle {
    width: 20px;
    height: 20px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #E4B575;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  